@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;700&display=swap');


*{
    font-family: 'Poppins', sans-serif;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }