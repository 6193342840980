.main-div {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  margin-top: 10vh;
  gap: 5%;
  flex-wrap: wrap;
}

#details-div {
  background-color: #cdebed;
  display: flex;
  width: 50%;
  height: 50vh;
  border-radius: 8px;
}
#project-name {
  margin-top: 2vh;
}

#live-demo-button {
  border: solid black;
  background-color: black;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
  height: 5vh;
  padding: 0.6em 1.3em;
  font-size: 1em;

  font-weight: 600;
  display: inline-block;

  text-align: center;
}
#all-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10vh;
  row-gap: 5vh;
  justify-content: space-around;
  margin-top: 5vh;
}
.margin {
  margin-bottom: 5vh;
}

#comment-div {
  border: none;
  background-color: #cdebed;
  border-radius: 8px;
  padding: 2vh;
}
.card-body {
  z-index: 1;
  box-sizing: border-box;
  padding: 1.25em;
  /* height: 220px; */
  width: 310px;
  background: white;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.056),
    0 6.7px 5.3px rgba(0, 0, 0, 0.081), 0 12.5px 10px rgba(0, 0, 0, 0.1),
    0 22.3px 17.9px rgba(0, 0, 0, 0.119), 0 41.8px 33.4px rgba(0, 0, 0, 0.144),
    0 100px 80px rgba(0, 0, 0, 0.2);
  transform: translateY(-150%);
  transition: 0.5s;
}
.card:hover img {
  transform: translateY(2%);
}
.card {
  border-radius: 8px;
  -webkit-tap-highlight-color: transparent;
}
/* #project-image{
    height: 30vh;
} */
.card img {
  width: 310px;
  height: 320px;
  position: relative;
  z-index: 2;
  transition: 00.5s;
  /* border: #1db0b9 solid 5px; */
}

.card:hover .card-body {
  transform: translateY(0);
}
#details-typo {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin-left: 5%;
}
.comments-container {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1150px) {
  .card img {
    transform: translateY(2%);
  }

  .card .card-body {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 750px) {
  #details-div {
    flex-direction: column;
    width: 90%;
    height: fit-content;
    padding: 1vh;
    margin-bottom: 3vh;
  }
  #project-image {
    width: 90%;
    margin: 1vh auto;
  }
  #comment-div {
    width: 90%;
  }
  #live-demo-button {
    width: 50%;
  }
}
